<template>
  <div class="main">
    <div class="search-area">
      <el-form :inline="true" class="form-inline">
        <el-form-item label="搜索">
          <el-input prefix-icon="el-icon-search" v-model="formInline.itemName" placeholder="输入项目名称等关键字搜索"></el-input>
        </el-form-item>
        <el-form-item label="票数排序：">
          <el-select v-model="formInline.sort" @change="handleSelectChange" placeholder="选择排序">
            <el-option label="由高到低" value="descending"></el-option>
            <el-option label="由低到高" value="ascending"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">搜索</el-button>
        </el-form-item>
      </el-form>
      <el-button type="danger" icon="el-icon-plus"  @click="dialogFormVisible = true">添加项目</el-button>
    </div>
    <el-table
      v-if="!screenTableVisible"
      :data="tableData"
      style="width: 100%"
    >
      <el-table-column
        fixed
        prop="orderNum"
        label="序号"
        width="70"
      >
      </el-table-column>
      <el-table-column
        prop="name"
        label="投票项目名称"
        width="360"
      >
      </el-table-column>
      <el-table-column
        prop="count"
        label="票数"
      >
      </el-table-column>
      <el-table-column
        label=""
        width="560"
      >
        <template slot-scope="scope">
          <el-progress :percentage="scope.row.scales" :show-text="false" :color="'#8088A8'"></el-progress>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="260"
      >
        <template slot-scope="scope">
          <el-popconfirm
            title="确定删除该活动吗？"
            @confirm="handleDelete(scope.row.id)"
          >
            <el-button
              type="danger"
              plain
              slot="reference"
            >删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-table
      v-if="screenTableVisible"
      :data="screenTableData"
      style="width: 100%"
    >
      <el-table-column
        fixed
        prop="orderNum"
        label="序号"
        width="70"
      >
      </el-table-column>
      <el-table-column
        prop="name"
        label="投票项目名称"
        width="360"
      >
      </el-table-column>
      <el-table-column
        prop="count"
        label="票数"
      >
      </el-table-column>
      <el-table-column
        label=""
        width="560"
      >
        <template slot-scope="scope">
          <el-progress :percentage="scope.row.scales" :show-text="false" :color="'#8088A8'"></el-progress>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="260"
      >
        <template slot-scope="scope">
          <el-popconfirm
            title="确定删除该活动吗？"
            @confirm="handleDelete(scope.row.id)"
          >
            <el-button
              type="danger"
              plain
              slot="reference"
            >删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="添加投票项目" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="活动名称" :label-width="formLabelWidth">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary"  @click="handleAddVotingItems">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formInline: {
        itemName: '',
        sort: ''
      },
      itemName: '',
      sort: '',
      tableData: [],
      screenTableData: [],
      screenTableVisible: false,
      dialogFormVisible: false,
      form: {
        name: ''
      },
      formLabelWidth: '120px'
    };
  },
  methods: {
    /**
     * 搜索
     */
    handleSearch() {
      console.log('formInline', this.formInline);
      if (this.itemName === this.formInline.itemName) {
        return false;
      } else {
        this.itemName = this.formInline.itemName;
        this.screenTableVisible = this.itemName === '' ? false : true;
        this.screenTableData = [];
        this.tableData.forEach(item => {
          if (item.name.indexOf(this.itemName) != -1) {
            this.screenTableData.push(item);
          }
        })
      }
    },
    /**
     * 数组对象方法排序：升序
     */
    ascendingSortByKey(array, key) {
      return array.sort(function(a, b) {   
        let x = a[key];
        let y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      });
    },
    /**
     * 数组对象方法排序：降序
     */
    descendingSortByKey(array, key) {
      return array.sort(function(a, b) {   
        let x = b[key];
        let y = a[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      });
    },
    /**
     * 排序
     */
    handleSelectChange(val) {
      if (this.sort === this.formInline.sort) {
        return false;
      } else {
        this.sort = val;
        // 降序
        if (this.sort === 'descending') {
          this.descendingSortByKey(this.tableData, 'scales');
          this.descendingSortByKey(this.screenTableData, 'scales');
          /* console.log('tableData', this.tableData);
          console.log('screenTableData', this.screenTableData); */
        }
        // 升序
        if (this.sort === 'ascending') {
          this.ascendingSortByKey(this.tableData, 'scales');
          this.ascendingSortByKey(this.screenTableData, 'scales');
          /* console.log('tableData', this.tableData);
          console.log('screenTableData', this.screenTableData); */
        }
      }
    },
    /**
     * 添加投票项目
     */
    handleAddVotingItems() {
      if (this.form.name) {
        this.dialogFormVisible = false;
        const token = sessionStorage.getItem("token") || "";
        this.$axios({
          href: "/api/app/meiye/tp/add",
          data: { token, name: this.form.name },
        }).then((res) => {
          console.log('addVoitingItemsRes', res);
          if (res.data.successful === 1) {
            this.getVoteList();
            this.itemName = '';
            this.formInline.itemName = '';
            this.sort = '';
            this.formInline.sort = '';
            this.screenTableVisible = false;
          } else {
            this.$message.error(res.data.message);
          }
        });
      } else {
        this.$message.warning('请先输入活动名称');
      }
    },
    /**
     * 删除投票活动
     */
    handleDelete(id) {
      const token = sessionStorage.getItem("token") || "";
      const itemId = id
      this.$axios({
        href: "/api/app/meiye/tp/del",
        data: { token, id },
      }).then((res) => {
        console.log('deleteRes', res);
        if (res.data.successful === 1) {
          this.getVoteList();
          const screenTableDataLen = this.screenTableData.length
          if (screenTableDataLen) {
            for (let i = 0; i < screenTableDataLen; i++) {
              if (this.screenTableData[i].id === itemId) {
                this.screenTableData.splice(i, 1);
                break;
              }
            }
          }
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    /**
     * 获取投票活动列表
     */
    getVoteList() {
      const token = sessionStorage.getItem("token") || "";
      this.$axios({
        href: "/api/app/meiye/tp/store",
        data: { token },
      }).then((res) => {
        console.log('res', res);
        if (res.data.successful === 1) {
          let data = res.data.data;
          let totalScales = 0;
          data.forEach(item => {
            totalScales = totalScales + item.count;
          })
          data.forEach((item, index) => {
            item.orderNum = index + 1 > 9 ? index + 1 : '0' + (index + 1);
            item.scales = totalScales > 0 ? Math.round((item.count / totalScales) * 100) : 0;
          })
          this.tableData = data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    }
  },
  created() {
    this.getVoteList()
  }
}
</script>

<style lang="scss" scoped>
  .main {
    width: 100%;
    padding: 10px 50px;
    box-sizing: border-box;
  }
  .search-area {
    display: flex;
    justify-content: space-between;
    min-width: 990px;
    margin-bottom: 20px;
    padding: 7px 10px 7px 0;
    background: #FFFFFF;
    border-radius: 6px 6px 0 0;
    box-sizing: border-box;
    .el-form {
      margin: 0;
      &-item {
        margin: 0;
        margin-right: 36px;
        /deep/ &__label {
          height: 36px;
          line-height: 36px;
          padding-right: 14px;
        }
        /deep/ .el-input__inner {
          width: 126px;
          height: 36px;
          background: #FFFFFF;
          font-size: 14px;
          color: #8088A8;
          border-color: #FFFFFF;
        }
      }
      &-item:first-child {
        /deep/ .el-form-item__label {
          width: 70px;
          padding: 0;
          font-size: 16px;
          font-weight: bold;
          color: #000000;
          text-align: center;
        }
        /deep/ .el-input__inner {
          width: 400px;
          height: 36px;
          background: #F8F8F8;
          font-size: 14px;
          border-color: #F8F8F8;
          border-radius: 18px;
        }
      }
      &-item:last-child {
        margin: 0;
        /deep/ .el-button {
          width: 100px;
          height: 36px;
          padding: 0;
          background: #8088A8;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 36px;
          border-color: #8088A8;
        }
      }
    }
    .el-button {
      width: 120px;
      height: 36px;
      padding: 0;
      background: #F57665;
      font-size: 14px;
      color: #FFFFFF;
      text-align: center;
      border-color: #F57665;
    }
  }
  .el-table {
    min-width: 990px;
    /deep/ th > .cell {
      font-size: 13px;
      font-weight: normal;
      color: #BBBBBB;
      text-align: center;
    }
    /deep/ td > .cell {
      font-size: 16px;
      color: #000000;
      text-align: center;
    }
    .el-button {
      width: 100px;
      height: 40px;
      padding: 0;
      font-size: 14px;
      line-height: 40px;
      border-radius: 6px;
    }
  }
</style>
